import type { ReactNode } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import type { GeoipContextValue } from '@utils/interfaces';

import { forbiddenCountryList, PHONE_MAP_BY_LOCALE, setPhone } from '../../../src/state/phone';

const defaultGeoipData = {
  cityName: '',
  countryIsoCode: '',
  countryName: '',
  subdivisionsIsoCode: '',
  subdivisionsName: ''
};

export const GeoipContext = createContext<GeoipContextValue>({
  geoipData: defaultGeoipData
});

export const GeoipContextProvider = ({ children }: { children: ReactNode }) => {
  const [geoipData, setGeoipData] = useState(defaultGeoipData);
  const value: GeoipContextValue = { geoipData };
  const dispatch = useDispatch();

  useEffect(() => {
    const requestGeoip = async () => {
      const pathPrefix = window.location.pathname.includes('/transfers/europe')
        ? '/transfers/europe'
        : '';
      const { data } = await axios.get(`${pathPrefix}/geoip`);
      setGeoipData(data);

      // todo disconnect this from redux, rewrite in proper ts
      if ('countryIsoCode' in data) {
        const countryNameCode = data.countryIsoCode;
        if (countryNameCode in forbiddenCountryList) {
          window.location.href = 'https://koronapay.com/static/forbidden.html';
        } else if (countryNameCode in PHONE_MAP_BY_LOCALE) {
          // @ts-ignore
          return dispatch(setPhone(PHONE_MAP_BY_LOCALE[countryNameCode]));
        }
      }
    };
    requestGeoip();
  }, []);

  return <GeoipContext.Provider value={value}>{children}</GeoipContext.Provider>;
};
