import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { GeoipContextProvider } from '../../srcv2/context/geoip/GeoipContext';

export const wrapWithReduxProvider = ({ element }) => {
  return <Provider store={store}>
    <GeoipContextProvider>
      {element}
    </GeoipContextProvider>
  </Provider>;
};
