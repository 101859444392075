import { createSlice } from '@reduxjs/toolkit';


export const PHONE_MAP_BY_LOCALE = {
    'EE': '+372 609 3024',       // Для Эстонии
    'GB': '+44 7480 802011',     // Для Великобритании
    'CZ': '+420 910 880 878',    // Для Чехии
    'FI': '+358 45 4900099',     // Для Финляндии
    'SE': '+46 10 551 35 15',    // Для Швеции
    'DE': '+49 1573 5999799',    // Для Германии
};


const initialState = {
    phoneNumber: '+49 1573 5999799'
};

export const forbiddenCountryList = ['LU', 'BE', 'NL'];

// Slice
const phoneSlice = createSlice({
    name: 'phone',
    initialState,
    reducers: {
        setPhone: (state, action) => {
            const { payload } = action;
            state.phoneNumber = payload;
        }
    }
});

// Reducers
export default phoneSlice.reducer;

// Selectors
export const phoneSelector = (state) => state.phone;

// Actions
export const { setPhone } = phoneSlice.actions;

