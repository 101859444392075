import {createSlice} from "@reduxjs/toolkit"
import axios from "axios";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import {filterTypes, DEFAULT_OFFSET_AGENT_LIST} from '../../constants/agents'

const AGENTS = "agents";

const INITIAL_STATE = {
    agentList: [],
    isLoading: false,
    inNeedUpdate: true,
    locale: 'ru',
    allowCurrencies: ['RUR', 'USD', 'EUR'],
    allowServicesId: [16, 17],
    offsetAgentList: 20,
    selectedCountryID: 'RU',
    filters: {
        open: false,
        services: [],
        currencies: []
    },
    currentCoordinates: null,
    agentsByCountyId: []
}

const agentSlice = createSlice({
    name: AGENTS,
    initialState: INITIAL_STATE,
    reducers: {
        setAgentList: (state, action) => {
            const {payload} = action;
            state.agentList = payload;
        },
        setLoading: (state, action) => {
            const {payload} = action;
            state.isLoading = payload;
        },
        setFilter: (state, action) => {
            const {payload} = action;
            state.filters = payload;
        },
        setUpdate: (state, action) => {
            const {payload} = action;
            state.inNeedUpdate = payload;
        },
        setOffsetAgentList: (state, action) => {
            const {payload} = action;
            state.offsetAgentList = payload;
        },
        setCurrentCoords: (state, action) => {
            const {payload} = action;
            state.currentCoordinates = payload
        },
        setLocale: (state , action) => {
            const {payload} = action;
            state.locale = payload;
        },
        setAgentsByCountryId: (state, action) => {
            const {payload} = action;
            state.agentsByCountyId = payload;
        },
    }
})

// Reducers
export default agentSlice.reducer;

// Selectors
export const agentSelector = (state) => state[AGENTS];
export const agentsListSelector = (state) => state[AGENTS].agentList.filter( agent => _checkMapFilter(state, agent));

// Actions
export const { setAgentList, setLoading, setFilter, setUpdate, setOffsetAgentList, setCurrentCoords, setLocale, setAgentsByCountryId } = agentSlice.actions;


const _getAgentList = (state) => {
    const agentList = [...state.agents.agentList];
    return agentList.filter(agent => _checkMapFilter(state, agent));
}

export const getAgentListByCountryId = (state) => {
    const agentList = [...state.agents.agentsByCountyId];
    return agentList.filter(agent => _checkMapFilter(state, agent));
}

export const initMap = (bounds, countryId = 'GB') => (dispatch) => {
        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();
        const defaultParams = {
            box: `${sw.lat()},${sw.lng()},${ne.lat()},${ne.lng()}`,
            addressObjectId: countryId
        };
        dispatch(_fetchAgents(defaultParams, countryId));

}

export const onChangeFilter = (type, name, value) => {
    return async (dispatch, getState) => {
        const currentFilters = getState().agents.filters;
        let filters = {};
        if (type === filterTypes.BOOL) {
            filters = {...currentFilters, [name]: !currentFilters[name]};
        } else if (type === filterTypes.ARRAY && value) {
            if (currentFilters[name].includes(value)) {
                filters = {
                    ...currentFilters,
                    [name]: currentFilters[name].filter(filterItem => filterItem !== value)
                }
            } else {
                filters = {...currentFilters, [name]: [...currentFilters[name], value]};
            }
        }
        dispatch(setFilter(filters));
        dispatch(setOffsetAgentList(DEFAULT_OFFSET_AGENT_LIST))
        // await this.getAgents(this.state.bounds, this.state.selectedCountryID);
    }
}

export const showMore = () => {
    return (dispatch, getState) => dispatch(setOffsetAgentList(getState().agents.offsetAgentList + DEFAULT_OFFSET_AGENT_LIST))
}


export const onResetFilters = () => {
    return (dispatch) => dispatch(setFilter(INITIAL_STATE.filters))
}

const _fetchAgents = (params) => {
    const { box = null, addressObjectId = 'GB' } = params;
    return async (dispatch, getState) => {
        const toggleUpdate = (state) => {
            const isNeedUpdate = getState().agents.inNeedUpdate;
            dispatch(setUpdate( state !== undefined ? state : !isNeedUpdate))
        }
        const prevCoords = getState().agents.currentCoordinates;
        const services = '16 or 17';
        let language = getState().agents.locale;
        if (!prevCoords || prevCoords.box !== box || prevCoords.locale !== language) {
            dispatch(setLoading(true));
            if (language === 'uk' || language === 'cy') {
                language = 'en';
            }

            let formatAddersId = addressObjectId;

            if (formatAddersId === 'UK' || formatAddersId === 'uk') {
                formatAddersId = 'GB'
            } else {
                formatAddersId = formatAddersId.toUpperCase();
            }

            try {
                const response = await axios({
                    method: 'get',
                    url: `/ags/agents`,
                    params: {
                        addressTruncateLevel: 'locality',
                        limit: 500,
                        fields: 'rootId,id,name,shortName,address,schedule,phone,googleLocation,services',
                        locationService: 'google',
                        box,
                        services,
                        addressObjectId: formatAddersId,
                        language: language
                    }
                });
                if (response && response.status === 200) {
                    const responseAgents = _formatAgents(response.data);
                    if (!isEqual(responseAgents, _getAgentList(getState()))) {
                       dispatch(setAgentList(responseAgents));

                    }
                    toggleUpdate(false)
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(setCurrentCoords({box, addressObjectId, locale: language}));
                dispatch(setLoading(false));
            }
        } else {
            toggleUpdate(false);
        }
    }
}

const _formatAgents = (agentsList) => {
    const filteredAgentList = agentsList
        .map(agent => ({
            lat: get(agent, 'googleLocation.latitude', ''),
            lng: get(agent, 'googleLocation.longitude', ''),
            ...agent
        }));

    return filteredAgentList
}
const _checkMapFilter = (state, agent) => {
    const {services, currencies} = state.agents.filters;
    let isAllow = true;
    const agentServices = get(agent, 'services', []);

    if (services.length && currencies.length) {
        isAllow =  services.every(service => agentServices.some(agentService => service === +agentService.id
        && currencies.every(currency => agentService.currencies.includes(currency))))
    }

    if (services.length && !currencies.length) {
        isAllow = services.every(service => agentServices.some(agentService => service === +agentService.id))
    }

    if (currencies.length && !services.length) {
        isAllow = agentServices.some(agentService => currencies.every(currency => agentService.currencies.includes(currency)))
    }

    return isAllow
}

export const fetchAgentsByCountryId = (addressObjectId, addressString) => {
    return async (dispatch, getState) => {
        let language = getState().agents.locale;
        let formatAddersId = addressObjectId.toUpperCase();
        let params = {
            addressTruncateLevel: 'locality',
            limit: 500,
            fields: 'rootId,id,name,shortName,address,schedule,phone,googleLocation,services',
            locationService: 'google',
            services: '16 or 17',
            language: (language === 'uk' || language === 'cy') ? 'en' : language,
            sort: 'relevance'
        };

        if (addressString) {
            params.queryString = addressString;
        } else {
            params.addressObjectId = formatAddersId === 'UK' ? "GB" : formatAddersId
        }

        try {
            const requestOptions = {
                method: 'get',
                url: `/ags/agents`,
                params
            };

            const response = await axios(requestOptions);
            if (response.status === 200) {
                dispatch(setAgentsByCountryId(response.data));
            }

        } catch (e) {
            console.error(e)
        }
    }
}




