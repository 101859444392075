import { configureStore } from '@reduxjs/toolkit';


import phone from "../state/phone";
import agents from "../state/agents"
import promo from "../state/promo"

const store = configureStore({
    reducer: {
        phone,
        agents,
        promo
    }
});

// Will be needed in future for TypeScript
// export type RootState = ReturnType<typeof rootReducer>;
// export type Dispatch = typeof store.dispatch;
// export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;