export const allowList = {
    CURRENCIES: 'currencies',
    SERVICES: 'servicesId'
};

export const filterTypes = {
    ARRAY: 'array',
    BOOL: 'bool'
};

export const DEFAULT_LOCALE = 'cy';
export const DEFAULT_OFFSET_AGENT_LIST = 20;