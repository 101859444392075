exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-de-js": () => import("./../../../src/pages/about/index.de.js" /* webpackChunkName: "component---src-pages-about-index-de-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-ru-js": () => import("./../../../src/pages/about/index.ru.js" /* webpackChunkName: "component---src-pages-about-index-ru-js" */),
  "component---src-pages-agents-index-de-js": () => import("./../../../src/pages/agents/index.de.js" /* webpackChunkName: "component---src-pages-agents-index-de-js" */),
  "component---src-pages-agents-index-en-js": () => import("./../../../src/pages/agents/index.en.js" /* webpackChunkName: "component---src-pages-agents-index-en-js" */),
  "component---src-pages-agents-index-ru-js": () => import("./../../../src/pages/agents/index.ru.js" /* webpackChunkName: "component---src-pages-agents-index-ru-js" */),
  "component---src-pages-blog-index-ru-js": () => import("./../../../src/pages/blog/index.ru.js" /* webpackChunkName: "component---src-pages-blog-index-ru-js" */),
  "component---src-pages-check-status-index-de-js": () => import("./../../../src/pages/check-status/index.de.js" /* webpackChunkName: "component---src-pages-check-status-index-de-js" */),
  "component---src-pages-check-status-index-en-js": () => import("./../../../src/pages/check-status/index.en.js" /* webpackChunkName: "component---src-pages-check-status-index-en-js" */),
  "component---src-pages-check-status-index-ru-js": () => import("./../../../src/pages/check-status/index.ru.js" /* webpackChunkName: "component---src-pages-check-status-index-ru-js" */),
  "component---src-pages-contacts-index-de-js": () => import("./../../../src/pages/contacts/index.de.js" /* webpackChunkName: "component---src-pages-contacts-index-de-js" */),
  "component---src-pages-contacts-index-en-js": () => import("./../../../src/pages/contacts/index.en.js" /* webpackChunkName: "component---src-pages-contacts-index-en-js" */),
  "component---src-pages-contacts-index-ru-js": () => import("./../../../src/pages/contacts/index.ru.js" /* webpackChunkName: "component---src-pages-contacts-index-ru-js" */),
  "component---src-pages-cookies-index-en-js": () => import("./../../../src/pages/cookies/index.en.js" /* webpackChunkName: "component---src-pages-cookies-index-en-js" */),
  "component---src-pages-cookies-index-ru-js": () => import("./../../../src/pages/cookies/index.ru.js" /* webpackChunkName: "component---src-pages-cookies-index-ru-js" */),
  "component---src-pages-events-index-de-js": () => import("./../../../src/pages/events/index.de.js" /* webpackChunkName: "component---src-pages-events-index-de-js" */),
  "component---src-pages-events-index-en-js": () => import("./../../../src/pages/events/index.en.js" /* webpackChunkName: "component---src-pages-events-index-en-js" */),
  "component---src-pages-events-index-ru-js": () => import("./../../../src/pages/events/index.ru.js" /* webpackChunkName: "component---src-pages-events-index-ru-js" */),
  "component---src-pages-help-index-de-js": () => import("./../../../src/pages/help/index.de.js" /* webpackChunkName: "component---src-pages-help-index-de-js" */),
  "component---src-pages-help-index-en-js": () => import("./../../../src/pages/help/index.en.js" /* webpackChunkName: "component---src-pages-help-index-en-js" */),
  "component---src-pages-help-index-ru-js": () => import("./../../../src/pages/help/index.ru.js" /* webpackChunkName: "component---src-pages-help-index-ru-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-mfaq-index-en-js": () => import("./../../../src/pages/mfaq/index.en.js" /* webpackChunkName: "component---src-pages-mfaq-index-en-js" */),
  "component---src-pages-mfaq-index-ru-js": () => import("./../../../src/pages/mfaq/index.ru.js" /* webpackChunkName: "component---src-pages-mfaq-index-ru-js" */),
  "component---src-pages-partners-index-de-js": () => import("./../../../src/pages/partners/index.de.js" /* webpackChunkName: "component---src-pages-partners-index-de-js" */),
  "component---src-pages-partners-index-en-js": () => import("./../../../src/pages/partners/index.en.js" /* webpackChunkName: "component---src-pages-partners-index-en-js" */),
  "component---src-pages-partners-index-ru-js": () => import("./../../../src/pages/partners/index.ru.js" /* webpackChunkName: "component---src-pages-partners-index-ru-js" */),
  "component---src-pages-test-calculator-index-de-js": () => import("./../../../src/pages/test-calculator/index.de.js" /* webpackChunkName: "component---src-pages-test-calculator-index-de-js" */),
  "component---src-pages-test-calculator-index-en-js": () => import("./../../../src/pages/test-calculator/index.en.js" /* webpackChunkName: "component---src-pages-test-calculator-index-en-js" */),
  "component---src-pages-test-calculator-index-ru-js": () => import("./../../../src/pages/test-calculator/index.ru.js" /* webpackChunkName: "component---src-pages-test-calculator-index-ru-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog/article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-faq-article-de-js": () => import("./../../../src/templates/faq/article_de.js" /* webpackChunkName: "component---src-templates-faq-article-de-js" */),
  "component---src-templates-faq-article-en-js": () => import("./../../../src/templates/faq/article_en.js" /* webpackChunkName: "component---src-templates-faq-article-en-js" */),
  "component---src-templates-faq-article-js": () => import("./../../../src/templates/faq/article.js" /* webpackChunkName: "component---src-templates-faq-article-js" */),
  "component---src-templates-faq-category-de-js": () => import("./../../../src/templates/faq/category_de.js" /* webpackChunkName: "component---src-templates-faq-category-de-js" */),
  "component---src-templates-faq-category-en-js": () => import("./../../../src/templates/faq/category_en.js" /* webpackChunkName: "component---src-templates-faq-category-en-js" */),
  "component---src-templates-faq-category-js": () => import("./../../../src/templates/faq/category.js" /* webpackChunkName: "component---src-templates-faq-category-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/templates/landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */)
}

