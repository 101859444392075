/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'babel-polyfill';
import { anchorate } from 'anchorate';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { wrapWithReduxProvider } from './src/state/provider';
import { PromoProvider } from './srcv2/context/promo/PromoContext';


// Create a client
const queryClient = new QueryClient()

export const onRouteUpdate = () => {
  anchorate();
};

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <PromoProvider>
      {wrapWithReduxProvider({element})}
    </PromoProvider>
  </QueryClientProvider>
)
