import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isActive: false,
};


const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {
        setVisiblePromo: (state, action) => { state.isActive = action.payload }
    }
});

export default promoSlice.reducer;

export const promoSelector = (state) => state.promo;

export const { setVisiblePromo } = promoSlice.actions;
