import type { ReactNode } from 'react';
import React, { useState, createContext } from 'react';

export interface ContextValue {
  isPromo: boolean;
  setVisiblePromo: () => void;
}

export const PromoContext = createContext<ContextValue>({
  isPromo: false,
  setVisiblePromo: () => {}
});

export const PromoProvider = ({ children }: { children: ReactNode }) => {
  const [isPromo, setPromo] = useState(true);
  const setVisiblePromo = () => setPromo(false);
  const value: ContextValue = { isPromo, setVisiblePromo };

  return <PromoContext.Provider value={value}>{children}</PromoContext.Provider>;
};
